import axios from "axios";

export default {
    sendContact(data){
       return axios.post('mail-contacta', data)
    },

    formIsComplete(form) {
        let res = true;
        if (form.nombre.length === 0){
            res = false;
        }
        if (form.correo.length === 0){
            res = false;
        }
        if (form.mensaje.length === 0){
            res = false;
        }

        /*Object.keys(form).forEach(key => {
            if(form[key].length === 0){
                res = false;
            }
        })*/
        return res
    },
    validarEmail(email){
        return /^[0-9a-zA-Z]+([0-9a-zA-Z]*[-._+])*[0-9a-zA-Z]+@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6}$/.test(email);
    }
}