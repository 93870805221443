<template>
  <div class="d-flex flex-column h-100">
    <section class="sectionc">
      <div class="seccionlore1 backrojo container-fluid">
        <p class="titulo1-1 textoblanco text-center">
          ¡Contáctame cuanto antes!
        </p>
        <div class="row">
          <div class="col-6">
            <div class="rueda1">
              <img
                  class="fon"
                  src="../assets/imagenes/inicio/telefonito.png"
                  alt=""
              />
            </div>
          </div>

          <div class="col-6">
            <div class="rueda2">
              <img
                  class="sob"
                  src="../assets/imagenes/inicio/sobre.png"
                  alt=""
              />
            </div>
          </div>

          <div class="col-6">
            <p style="margin: 3% auto auto 40%" class="texto5 textoblanco">
              608 374 684
            </p>
          </div>

          <div class="col-6">
            <p style="margin: 3% auto auto auto" class="texto5 textoblanco">
              info@loredelatorre.es
            </p>
          </div>
        </div>
      </div>
    </section>

    <!---------------------------Contenido----------------------------->

    <div style="margin-right: 10em" class="container-fluid contcon">
      <div class="row">
        <div class="col-5 columna">
          <div>
            <img
                src="../assets/imagenes/inicio/mariposa.png"
                alt=""
                class="mariposacon"
            />
          </div>
        </div>
        <div class="col-7 columna">
          <div>
            <form class="formulario1">
              <p style="max-width: 35%" class="nom textoform">nombre</p>
              <p>
                <br/><input class="formcuadro" type="text" name="nombre" v-model="form.nombre"/>
              </p>
              <p style="max-width: 70%" class="corr textoform">
                correo electrónico
              </p>
              <p>
                <br/><input class="formcuadro" type="text" name="correo" v-model="form.correo"/>
              </p>
              <p style="max-width: 40%" class="tel textoform">teléfono</p>
              <p>
                <br/><input class="formcuadro" type="text" name="telefono" v-model="form.telefono"/>
              </p>
              <p style="max-width: 40%; max-height: 1%" class="men textoform">mensaje</p>
              <p>
                <br/><textarea
                  name="mensaje"
                  id=""
                  cols="15"
                  rows="5"
                  class="formcuadromen" v-model="form.mensaje"
              ></textarea>
              </p>
              <p>
                <input class="botonform" type="button" value="Enviar" @click="enviarForm()" v-if="!enviando"/>
              </p>
              <div class="spinner-border text-info" role="status" v-if="enviando">
                <span class="visually-hidden">Loading...</span>
              </div>
            </form>
          </div>
          <div v-if="!isValidForm" class="alert alert-danger">
            <h2>{{mensajeAlerta}}</h2>
          </div>
          <div v-if="enviado" class="alert alert-info">
            <h2 class="">El correo se ha enviado, pronto recibiras respuesta!</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactaService from "../services/ContactaService";

export default {
  name: "Contacta",
  data() {
    return {
      enviando: false,
      isValidForm: true,
      enviado: false,
      form: {
        nombre: '',
        correo: '',
        telefono: '',
        mensaje: '',
      },
      formDefault: {
        nombre: '',
        correo: '',
        telefono: '',
        mensaje: '',
      },
      mensajeAlerta: ''
    }
  },
  mounted() {
    this.$store.commit("SET_FOOTER", [true,'backrojo']);
  },
  methods: {
    enviarForm(){
      if(!ContactaService.formIsComplete(this.form)){
        // console.log('debe llenar todos los campos')
        this.isValidForm = false;
        this.mensajeAlerta = 'El nombre, correo y mensaje son obligatorios!';
        setTimeout(()=>{
          this.isValidForm = true
        },5000)
        return false;
      }
      if(!ContactaService.validarEmail(this.form.correo.trim())){
        this.isValidForm = false;
        this.mensajeAlerta = 'El correo ingresado NO es valido!';
        setTimeout(()=>{
          this.isValidForm = true
        },5000)
        return false;
      }
      this.enviando = true
      ContactaService.sendContact(this.form)
      .then(response => {
        this.liberarForm()
        this.enviado = true
        setTimeout(()=>{
          this.enviado = false
        },5000)
      })
      .catch(error => {
        this.isValidForm = false;
        this.mensajeAlerta = JSON.stringify(error);
        setTimeout(()=>{
          this.isValidForm = true
        },5000)
        this.liberarForm()
      })
    },

    liberarForm(){
      this.form = Object.assign({}, this.formDefault)
      this.enviando = false
    }
  }
}
</script>

<style scoped>

</style>